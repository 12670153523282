import { replacePathParams } from '../../helpers/urlHelpers';
import { apiService } from '../api';

const ENDPOINTS = {
  ONLINE_GENERAL_CONFIGURATION:'/online-general-configuration',
  FIND_ONLINE_GENERAL_CONFIGURATION:'/online-general-configuration/key/:key',
  CONFIGURATION_IMAGES:'/configuration-images',
  DELETE_CONFIGURATION_IMAGES: '/configuration-images/:imagePath'
}

class ConfigurationsService {
  async saveTicketBannerImage(formData) {
    return await apiService.post(ENDPOINTS.CONFIGURATION_IMAGES, formData);
  }

  async deleteLanguageTicketBanner(imagePath) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE_CONFIGURATION_IMAGES, {imagePath}))
  }

  async getOnlineGeneralConfiguration() {
    return await apiService.get(ENDPOINTS.ONLINE_GENERAL_CONFIGURATION);
  }

  async findOnlineGeneralConfiguration(key) {
    const {data} = await apiService.get(replacePathParams(ENDPOINTS.FIND_ONLINE_GENERAL_CONFIGURATION, {key}))
    return data.value
  }

  async saveOnlineGeneralConfiguration(data) {
    return await apiService.post(ENDPOINTS.ONLINE_GENERAL_CONFIGURATION, data);
  }
}

export const configurationsService = new ConfigurationsService()
