import HistoryLogMessageModalComponent from "@/shared/history-log-message-modal/HistoryLogMessageModalComponent.vue";
import {historyLogsService} from "@/services/history-logs";

export const historyLoggerMixin = {
  data() {
    return {
      originalMethod: () => {},
    }
  },

  methods: {
    async handleAccept(message, originalMethod, resolve, reject) {
      try {
        const result = await originalMethod();

        if (!message) {
          resolve(result);
          return;
        }

        await historyLogsService.updateHistoryLog(result.data.historyLogId, {message});
        resolve(result);
      } catch (error) {
        reject(error);
      }
    },

    async historyLoggerDecorator(originalMethod) {
      return async function (...args) {
        return new Promise((resolve, reject) => {
          try {
            this.$modal.show(
              HistoryLogMessageModalComponent,
              {
                onAccept: async (message) => {
                  this.originalMethod = originalMethod.bind(this, ...args);

                  await this.handleAccept(message, this.originalMethod, resolve, reject);
                }
              },
              { height: 'auto', scrollable: true }
            );
          } catch (error) {
            reject(error);
          }
        });
      };
    },
  }
}
