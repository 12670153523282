<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home c-breadcrumbs__home--disabled"
            @click="goToHome"
          > 
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>

        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Users</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-search">
        <div class="c-search-filter-row">
          <div class="u-flex">
            <div class="u-flex-row-center">
              <md-field class="u-width-m u-mb-xs u-mr-md">
                <label>Search</label>
                <md-input v-model="searchQuery" @keydown.enter="getUsers(1)" />
              </md-field>
              <div class="u-pt-md">
                <md-button v-if="searchQuery" class="md-icon-button" @click="resetSearch()">
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </div>
          </div>
          <div>
            <generic-button variation="red" :disabled="searchQuery === previousSearchQuery" @click="getUsers(1)">
              Search
            </generic-button>
            <md-tooltip v-show="searchQuery === previousSearchQuery">
              You must enter a new search term
            </md-tooltip>
          </div>
        </div>
      </div>

      <div v-if="fetchedResults && users.length === 0" class="c-search-result-not-found">
        No results found
      </div>
      <div class="c-table-wrapper">
        <base-table-component
          :prop-data="users"
          sortable
          @sort="handleSort"
        >
          <template v-slot:action>
            <div class="header-table-section">
              Users
            </div>
            <div class="add-button">
              <div>
                List of available users
              </div>
              <div class="add-section">
                <img src="../../assets/add-button.svg" class="add-button__new" alt="" @click="createUser()"> <span>Add New User</span>
              </div>
            </div>
          </template>
          <template
            v-slot:default="slotProps"
          >
            <span class="c-table__btn-wrap">
              <router-link :to="{ name: 'users-edit', params: { id: slotProps.selected.rowData.id } }">
                <generic-button
                  v-if="user.id !== slotProps.selected.rowData.id && user.role === 1 && slotProps.selected.rowData.role === userRoles.manager"
                  icon
                  transparent
                  :class="{'edit-admin-btn': user.id == slotProps.selected.id}"
                  text-inherit
                >
                  <md-tooltip
                    md-direction="top"
                  >
                    Edit
                    {{ slotProps.selected.rowData }}
                  </md-tooltip>
                  <img src="../../assets/edit-button.svg" alt="">
                </generic-button>
              </router-link>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="user.id !== slotProps.selected.rowData.id && user.role === 1"
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <img src="../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="paginationData.pageCount"
        :force-page="currentPage"
        :page-range="limitPerPage"
        :click-handler="getNextPage"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations  } from "vuex";
import CreateUserModal from "./create-update";
import { userService } from '../../services/user';
import { paginationService } from '../../services/pagination/index';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import DeleteModalComponent from '../../shared/delete-modal/DeleteModalComponent';
import GenericButton from '../../shared/buttons/GenericButton';
import { get } from 'lodash';
import { userRoles } from '../../const/roles';

export default {
  components: {
    BaseTableComponent,
    GenericButton
  },
  data() {
    return {
      users: [],
      paginationData: {},
      limitPerPage: 10,
      currentPage: 1,
      userRoles,
      searchQuery: '',
      previousSearchQuery: null,
      sortColumnActive: '',
      sortColumnDirection: '',
      fetchedResults: false,
    }
  },

  computed: {
    ...mapGetters("account", ["user"]),

    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    },
  },

  async created() {
    this.getUsers();
  },

  methods: {
    ...mapMutations('user', ['FETCHED_USERS']),
    createUser() {
      this.$modal.show(
        CreateUserModal,
        {
          userData: userService.formatUserDataData(),
          pageCount: this.paginationData.pageCount,
          currentPage: this.currentPage,
          getNextPage: this.getNextPage
        },
        { height: "auto", scrollable: true }
      );
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async getUsers(currentPage = 1, limit = 10, force = false) {
      if (
        !force &&
        (this.searchQuery === this.previousSearchQuery && 
        this.limitPerPage === limit && 
        this.currentPage === currentPage)
      ) {
        return;
      }

      this.currentPage = currentPage;
      const searchConstraints = {
        ...(this.searchQuery ? { term: this.searchQuery} : {}),
        ...(this.currentPage ? { page: this.currentPage} : {}),
        ...(this.sortColumnActive ? { sortColumn: this.sortColumnActive } : {}),
        ...(this.sortColumnDirection ? { sortDirection: this.sortColumnDirection } : {}),
        limit
      }
      const response = await userService.getUsers(searchConstraints);

      if (this.shouldGoBackOnePage(response)) {
        this.getNextPage(this.currentPage - 1);

        return;
      }

      this.FETCHED_USERS(get(response, 'data.items', []));
      this.paginationData = paginationService.getPaginationData(response.data);
      this.users = userService.prepareUsersDataFromResponse(response.data.items);
      this.previousSearchQuery = this.searchQuery;
      this.fetchedResults = true;
    },

    getNextPage(page) {
      this.currentPage = page
      this.getUsers(page, this.limitPerPage, true);
    },

    async deleteUser(id) {
      return await userService.deleteUser(id);
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `user with email "${selected.email}`,
          propData: selected.id,
          deleteFunction: this.deleteUser,
          getDataFunction: this.getNextPage,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    isOnLine() {
      return window.location.pathname.includes('on-line');
    },

    handleSort(column, direction) {
      this.sortColumnActive = column;
      this.sortColumnDirection = direction.toUpperCase();
      this.getUsers(this.currentPage, this.limitPerPage, true);
    },

    resetSearch() {
      this.searchQuery = '';
      this.getUsers(1, this.limitPerPage, true);
    },


    shouldGoBackOnePage(response) {
      return response.data.items.length === 0 && response.data.pageCount < this.currentPage && response.data.pageCount !== 0;
    },
  }
};
</script>

<style lang="scss" scoped>
  .edit-admin-btn {
    margin-right: 52px !important;
  }

  .c-search {
    box-shadow: 4px 3px 6px -6px $grey-dark-01;
    padding: 15px 20px;
  }

  .c-search-filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .u-mr-md {
      margin-right: 16px !important;
    }
  }

  .c-search-additional-fields {
    display: flex;
    align-items: baseline;

    > div {
      margin-right: 10px;
    }
  }

  .c-search-result-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 100px;
  }
</style>