<template>
  <div
    v-if="tinyMceApiKey.length"
    id="wysywigWrapper"
    ref="wrapper"
    :class="`wrapper ${deviceClass === 'web' ? 'web' : 'mobile'}`"
  >
    <div class="preview-width-wrapper">
      <generic-button :variation="deviceClass === 'web' ? 'orange' : ''" @click="handlePreviewWidth('web')">
        Desktop preview
      </generic-button>
      <generic-button :variation="deviceClass === 'mobile' ? 'orange' : ''" @click="handlePreviewWidth('mobile')">
        Mobile preview
      </generic-button>
    </div>
    <Editor
      :api-key="tinyMceApiKey"
      :value="value"
      :init="wysiwygConfig"
      @input="update"
    />
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import {configurationsService} from "@/services/configurations";
import initCustomButtonPlugin from "@/shared/wysiwyg-component/plugins/custombutton";
import GenericButton from "@/shared/buttons/GenericButton.vue";

export default {
  name: 'WysiwygComponent',
  components: {GenericButton, Editor},
  props: {
    value: {
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    theme: {
      required: false,
      default: 'white',
      type: String,
    }
  },


  data() {
    return {
      tinyMceApiKey: '',
      deviceClass: 'web',
      wysiwygConfig: {
        width: '100%',
        height: '600px',
        paste_data_images: true,
        automatic_uploads: false,
        powerpaste_allow_local_images: true,
        skin: 'material-classic',
        content_css: this.theme === 'dark' ? '/wysiwyg-styles-dark.css' : '',
        plugins: 'custombutton print preview casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap mentions quickbars linkchecker emoticons advtable export',
        toolbar: 'custombutton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        setup: this.loadPlugins,
        images_upload_handler: () => {},
      }
    }
  },

  async created() {
    this.tinyMceApiKey = await configurationsService.findOnlineGeneralConfiguration('tinyMceApiKey')
  },

  methods: {
    update(newValue) {
      this.$emit('input', newValue);
    },

    loadPlugins() {
      window.tinymce.PluginManager.add('custombutton', initCustomButtonPlugin)
    },

    handlePreviewWidth(deviceClass) {
      this.deviceClass = deviceClass
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  &.mobile {
    width: 375px;
  }
}

.preview-width-wrapper {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
</style>

