<template>
  <div class="l-main">
    <div class="c-general-configuration">
      <div class="l-main__ctn u-overflow-auto">
        <form
          novalidate
          @submit.prevent="validateAndSave"
        >
          <md-field>
            <label for="apiKey">TinyMCE API Key</label>
            <md-input
              v-model="generalConfiguration.tinyMceApiKey"
              name="tinyMceApiKey"
              type="string"
              class="md-input"
              autocomplete="off"
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.GENERAL.key}`)"
            />
            <span class="md-helper-text">API key used for TinyMCE Wysiwyg editor on backoffice. Get an API key from: <a href="https://www.tiny.cloud/my-account/integrate/#angular">https://www.tiny.cloud/my-account/integrate/#angular</a></span>
          </md-field>

          <div class="u-ml-auto u-mt-md">
            <generic-button
              variation="red"
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.GENERAL.key}`)"
              @click="validateAndSave"
            >
              Save
            </generic-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {PERMISSION_NAMES} from "@/const/online-permissions";
import {mapActions} from "vuex";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {validationBaseMixin} from '@/mixins/ValidationBaseMixin';
import GenericButton from "../../../../../shared/buttons/GenericButton";
import {configurationsService} from "@/services/configurations";

export default {
  name: "GeneralConfiguration",

  components: {
    GenericButton
  },

  mixins: [onlinePermissionsMixin, validationBaseMixin],

  validations: {
    generalConfiguration: {}
  },

  data() {
    return {
      generalConfiguration: {
        tinyMceApiKey: '',
      },
    }
  },

  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
  },

  async created() {
    await this.getGeneralConfiguration();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),

    goToHome() {
      this.$router.push({name: 'home'});
    },

    async getGeneralConfiguration() {
      try {
        const { data } = await configurationsService.getOnlineGeneralConfiguration();
        this.generalConfiguration = data.reduce((result, databaseConfig) => {
          result[databaseConfig.key] = databaseConfig.value
          return result;
        }, {});

      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },


    validateAndSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveGeneralConfiguration();
      }
    },

    async saveGeneralConfiguration() {
      try {

        await configurationsService.saveOnlineGeneralConfiguration({...this.generalConfiguration});
        this.handleSuccess({message: "General Configuration updated successfully!"});

      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.c-general-configuration {
  display: flex;
  flex: 1;
  max-height: 100%;
}
</style>
