import axios from 'axios'
import config from '../../../config/local'
import assign from 'lodash/assign'
import { apiService } from '../api';

const ENDPOINTS = {
  TOKEN: '/api-rest/get-token',
}

class RestApiService {

  constructor() {
    this.client = axios.create({});
  }

  async get(url) {
    try {
      await this.getAndSetToken();

      const response = await  this.client.get(`${config.REST_URL}${url}`)

      return response
    } catch(error) {
      throw error
    }
  }

  async getTokenFromRestApi() {
    try {
      const response = await apiService.get(ENDPOINTS.TOKEN);

      return response.data;
    } catch(error) {
      throw error;
    }
  }

  async getAndSetToken() {
    const data = await this.getTokenFromRestApi();
    assign(this.client.defaults.headers, {
      'Authorization':  `Bearer ${data.token}`
    })
  }
}

export const restApiService = new RestApiService()
