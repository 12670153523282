<template>
  <div class="l-main">
    <md-tabs class="c-tabs c-tabs--small" :md-active-tab="currentLanguage.code">
      <md-tab
        v-for="language in languages"
        :id="language.code"
        :key="language.code"
        :md-label="language.code"
        @click="changeLanguage(language)"
      />
    </md-tabs>

    <div class="l-main__ctn">
      <div class="c-search">
        <div class="c-search-filter-row">
          <div class="u-flex">
            <div class="u-flex-row-center">
              <md-field class="u-width-m u-mb-xs u-mr-md">
                <label>Search</label>
                <md-input v-model="searchQuery" @keydown.enter="searchSeo(true)" />
              </md-field>
              <div class="u-pt-md">
                <md-button v-if="searchQuery" class="md-icon-button" @click="resetSearch()">
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </div>
          </div>
          <div>
            <generic-button variation="red" :disabled="searchQuery.length < 1" @click="searchSeo(true)">
              Search
            </generic-button>
            <md-tooltip v-show="searchQuery.length < 1">
              You must enter a character to search SEO rules
            </md-tooltip>
          </div>
        </div>
      </div>

      <div v-if="fetchedResults && searchItems.length === 0" class="c-search-result-not-found">
        No results found
      </div>

      <div class="c-table-wrapper l-casino-category-seo__table">
        <base-table-component
          :prop-data="seoRulesTableData"
          sortable
          @sort="handleSort"
        >
          <template v-slot:default="slotProps">
            <generic-button
              icon
              transparent
              @click="editSeoRule(slotProps.selected.seoRule)"
            >
              <md-tooltip md-direction="top">
                Edit seo rule
              </md-tooltip>
              <md-icon>arrow_forward_ios</md-icon>
            </generic-button>
          </template>
        </base-table-component>
      </div>

      <paginate
        v-if="totalPages > 1"
        v-model="currentPage"
        :page-count="totalPages"
        :prev-text="'<'"
        :next-text="'>'"
        :page-range="20"
        :click-handler="searchSeo"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import GenericButton from '../../../../../shared/buttons/GenericButton';
import { jsonParse } from '@/helpers/transformers'
import { seoRulesService } from '@/services/seo-rules'
import get from 'lodash/get'
import { languageService } from '@/services/language'
import BaseTableComponent from '@/shared/table/BaseTableComponent'
import EditSeoRuleModal from './edit'
import {casinoCategoriesService} from "@/services/casino-category";
import { mapActions } from 'vuex';

export default {
  name: "CasinoCategories",

  components: {
    GenericButton,
    BaseTableComponent
  },

  props: {
    website: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      searchQuery: "",
      searchSportQuery: "",
      searchRegionQuery: "",
      selectedFilter: 'casino-category',
      searchItems: [],
      fetchedResults: false,
      languages: [],
      currentLanguage: {},
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      defaultLanguageId: null,
      sortColumnActive: '',
      sortColumnDirection: '',
    };
  },

  computed: {
    seoRulesTableData() {
      return this.searchItems.map(seoRule => ({
        seoRule: seoRule,
        rowData: {
          name: seoRule.translation,
          ...this._getEntities(seoRule),
          id: this._getEntityId(seoRule),
        }
      }));
    },
  },

  created() {
    this.getAvailableLanguages();
    this.searchCasinoSeo();
  },

  methods: {
    ...mapActions("response", ["setGlobalError"]),
    async searchSeo(page = 1) {
      this.currentPage = typeof page !== 'number' ? 1 : page;

      const searchConstraints = {
        ...(this.searchQuery ? { term: this.searchQuery} : {}),
        ...(this.selectedFilter ? { filter: this.selectedFilter} : {}),
        ...(this.currentPage ? { page: this.currentPage} : {}),
        ...(this.defaultLanguageId ? { defaultLanguageId: this.defaultLanguageId} : {}),
        ...(this.sortColumnActive ? { sortColumn: this.sortColumnActive } : {}),
        ...(this.sortColumnDirection ? { sortDirection: this.sortColumnDirection } : {}),
        ...(this.website ? { websiteId: this.website.id} : {}),
      }

      const searchResponse = await seoRulesService.search(this.currentLanguage.id, searchConstraints)

      const {items, pageCount} = searchResponse.data;

      this.searchItems = items;
      this.totalPages = pageCount;

      this.fetchedResults = true;
    },

    async searchCasinoSeo() {
      const response = await seoRulesService.getCasinoCategories(this.website)
      const categories = response.data.payload;

      try {
        await casinoCategoriesService.create(categories, this.website.id);
      } catch (error) {
        this.setGlobalError(error.data.message);
      }

      return this.searchSeo()
    },

    changeLanguage(language) {
      this.currentLanguage = language;
      this.searchSeo();
    },

    async getAvailableLanguages() {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguages = jsonParse(get(this, 'website.betshop.market.additionalLanguages')) || [];
      const defaultLanguage = get(this, 'website.betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguage, ...additionalLanguages])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code))
      this.currentLanguage = this.languages[0];
      this.defaultLanguageId = this.languages.find(language => language.code === 'en')?.id
    },

    editSeoRule(seoRule) {
      this.$modal.show(
        EditSeoRuleModal,
        {
          seoRule,
          website: this.website,
          language: this.currentLanguage
        },
        { height: 'auto', scrollable: true, width: '80%' }
      );
    },

    _getEntities(seoRule) {
      const entities = {
        games: '',
        active: '',
        hidden: '',
      }

      entities.active = seoRule.target.active;
      entities.hidden = seoRule.target.hidden;
      entities.games = seoRule.target.numberOfGames;

      return entities;
    },

    _getEntityId(seoRule) {
      switch (seoRule.entityType) {
        case 'CasinoCategoryEntity':
          return seoRule.target.casinoCategoryId;
        default:
          return '-'
      }
    },

    handleSort(column, direction) {
      this.sortColumnActive = column;
      this.sortColumnDirection = direction;
      this.searchSeo(this.currentPage);
    },

    resetSearch() {
      this.searchQuery = '';
      this.searchSeo(true);
    }
  },
};
</script>

<style lang="scss" scoped>
  .c-search {
    box-shadow: 4px 3px 6px -6px $grey-dark-01;
    padding: 15px 20px;
  }

  .c-search-filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .u-mr-md {
      margin-right: 16px !important;
    }
  }

  .c-search-additional-fields {
    display: flex;
    align-items: baseline;

    > div {
      margin-right: 10px;
    }
  }

  .c-search-result-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 100px;
  }
</style>
