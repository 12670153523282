<template>
  <div class="l-main__ctn">
    <template v-if="getSelectedDomainId">
      <div class="md-layout languages-wrapper">
        <generic-button
          v-for="language in languages"
          :key="language.code"
          :variation="language.code === selectedLanguage.code ? 'orange' : ''"
          @click="changeLanguage(language)"
        >
          {{ language.name }}
        </generic-button>
      </div>

      <label class="field-label">Content</label>
      <wysiwyg-component
        v-model="content[selectedLanguage.code]"
        :disabled="!isAdmin()"
        theme="dark"
        @input="checkIfInputsHaveChanged"
      />

      <div class="u-flex-right">
        <div class="u-flex">
          <generic-button
            variation="red"
            :disabled="!isAdmin() || !inputsChanged"
            @click="handleUpdateOrCreate"
          >
            {{ updateOrCreateLabel }}
          </generic-button>
        </div>
      </div>
    </template>

    <template v-if="!getSelectedDomainId">
      <div class="l-empty-container u-full-height">
        <md-empty-state
          md-icon="announcement"
          md-label="You must create a domain first"
        />
      </div>
    </template>
  </div>
</template>

<script>

import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import GenericButton from "@/shared/buttons/GenericButton.vue";
import {mapActions, mapGetters} from "vuex";
import {websiteService} from "@/services/websites";
import {languageService} from "@/services/language";
import {jsonParse} from "@/helpers/transformers";
import {get, isArray} from "lodash";
import WysiwygComponent from "@/shared/wysiwyg-component/WysiwygComponent.vue";
import {helpService} from "@/services/help";
import {historyLoggerMixin} from "@/mixins/historyLoggerMixin";

export default {
  name: "HelpContactFormComponent",
  components: {
    WysiwygComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin, historyLoggerMixin],
  props: {
    domain: {
      require: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      inputsChanged: false,
      languages: [],
      selectedLanguage: {},
      contactForm: {},
      content: {},
    };
  },

  computed: {
    ...mapGetters('helpDomains', ['getSelectedDomainId', 'getSelectedWebsiteId']),

    updateOrCreateLabel() {
      return this.contactForm.id ? 'Update' : 'Create';
    }
  },

  watch: {
    getSelectedDomainId() {
      this.getAllLanguagesForWebsite();
      this.fetchContactForm();
    }
  },

  async created() {
    this.createContactForm = await this.historyLoggerDecorator(helpService.createContactForm);
    this.updateContactForm = await this.historyLoggerDecorator(helpService.updateContactForm);

    this.getAllLanguagesForWebsite();
    await this.fetchContactForm();

    this.inputsChanged = false;
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),

    async fetchContactForm() {
      if (!this.getSelectedDomainId) {
        return;
      }

      const response = await helpService.getContactForm(this.getSelectedDomainId);
      this.contactForm = response.data;
      this.content = response.data.content || {};
    },

    checkIfInputsHaveChanged() {
      this.inputsChanged = true;
    },

    async handleUpdateOrCreate() {
      const body = {
        content: JSON.stringify(this.content),
      }

      try {
        const response = !this.contactForm.id ?
          await this.createContactForm(body, this.getSelectedDomainId) :
          await this.updateContactForm(body, this.getSelectedDomainId);

        const successMessage = !this.contactForm ?
          'Help Contact Form Created' :
          'Help Contact Form Updated';

        this.contactForm = response.data;
        this.handleSuccess({message: successMessage});

      } catch (err) {
        this.setGlobalError(isArray(err.data.message) ? err.data.message[0] : err.data.message);
      }
    },

    getAllLanguagesForWebsite() {
      if (!this.getSelectedWebsiteId) {
        return;
      }

      this.getWebsite(this.getSelectedWebsiteId)
    },

    async getWebsite(websiteId) {
      const response = await websiteService.getSingleWebsite(websiteId)
      await this.getAvailableLanguages(response.data);
    },

    async getAvailableLanguages(data) {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguageCodes = jsonParse(get(data, 'betshop.market.additionalLanguages')) || [];
      const defaultLanguageCode = get(data, 'betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguageCode, ...additionalLanguageCodes])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code));
      this.selectedLanguage = this.languages[0];
      this.canShowForm = true;
    },

    changeLanguage(language) {
      this.selectedLanguage = language;
    },

  },
};
</script>

<style lang="scss" scoped>
.l-empty-container {
  margin-bottom: 0;
}

.md-empty-state {
  max-width: 80% !important;
  justify-content: flex-start;
}

.u-flex-right {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.languages-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

  button {
    min-width: auto;
    padding: 5px 10px;
  }
}

.field-label {
  margin-top: 30px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}

</style>
