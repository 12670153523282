<template>
  <base-modal-component>
    <template v-slot:header>
      <div class="u-flex-column">
        <h4 class="u-typography-color md-title">
          Update Seo Rule
          {{ canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`) }}
        </h4>
        <div class="c-header__info">
          <p class="c-header__info-label">
            Website: <span>{{ website.name }}</span>
          </p>
          <p class="c-header__info-label">
            Language: <span>{{ language.name }}</span>
          </p>
          <p class="c-header__info-label">
            {{ pageType }}: <span>{{ pageInfo }}</span>
          </p>
        </div>
      </div>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <md-field>
        <label>Meta Title</label>
        <md-input
          v-model="singleSeoRule.metaTitle"
          name="metaTitle"
          type="text"
          class="md-input"
          autocomplete="off"
          :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)"
        />
      </md-field>
      <md-field>
        <label>Meta Description</label>
        <md-textarea
          v-model="singleSeoRule.metaDescription"
          name="metaDescription"
          type="text"
          class="md-input"
          autocomplete="off"
          md-autogrow
          :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)"
        />
      </md-field>
      <md-field>
        <label>Page Title - H1</label>
        <md-input
          v-model="singleSeoRule.pageTitle"
          name="pageTitle"
          type="text"
          class="md-input"
          autocomplete="off"
          :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)"
        />
      </md-field>
      <label>Content</label>
      <wysiwyg-component
        v-model="singleSeoRule.content"
        :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)"
      />
      <md-checkbox
        v-model="singleSeoRule.noindex"
        :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)"
      >
        Meta noindex
      </md-checkbox>
    </template>

    <template v-slot:footer>
      <div class="u-text-align-right">
        <div class="u-flex">
          <generic-button
            variation="grey"
            class="u-mr-md"
            @click="close"
          >
            Close
          </generic-button>
          <generic-button
            variation="red"
            :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`)"
            @click="submit"
          >
            {{ update }}
          </generic-button>
        </div>
      </div>
    </template>
  </base-modal-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { seoRulesService } from '@/services/seo-rules'
import { seoRulesTargetTypeMapper } from '@/const/seo-rules'
import GenericButton from '@/shared/buttons/GenericButton'
import BaseModalComponent from '@/shared/modal/BaseModalComponent'
import { responseService } from '@/services/response'
import WysiwygComponent from '@/shared/wysiwyg-component/WysiwygComponent';
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  name: 'EditSeoRuleModal',
  components: {
    WysiwygComponent,
    GenericButton,
    BaseModalComponent
  },

  mixins: [onlinePermissionsMixin],

  props: {
    seoRule: {
      type: Object,
      required: true,
    },
    website: {
      type: Object,
      required: true
    },
    language: {
      type: Object,
      required: true
    },
    handleSuccessCallback: {
      type: Function,
      required: false,
      default: () => {}
    }
  },

  data() {
    return {
      singleSeoRule: {
        content: '',
        metaDescription: '',
        metaTitle: '',
        pageTitle: '',
        noindex: false,
      },
      messages: {
        error: null,
        success: null,
      },
    };
  },

  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    ...mapGetters("response", ["success", "error"]),

    update() {
      return !this.singleSeoRule.id ? 'Create' : 'Update';
    },

    pageType() {
      return seoRulesTargetTypeMapper[this.seoRule.entityType];
    },

    pageInfo() {
      switch (this.seoRule.entityType) {
        case 'RegionEntity':
          return `${ this.seoRule.target.sport.name } - ${ this.seoRule.translation }`;
        case 'LeagueEntity':
          return `${ this.seoRule.target.region.sport.name } - ${ this.seoRule.target.region.name } - ${ this.seoRule.translation }`;
        case 'CasinoCategoryEntity':
          return `${ this.seoRule.translation }`;
        default:
          return `${ this.seoRule.translation }`;
      }
    },
  },

  created() {
    this.getSeoRule();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    async getSeoRule() {
      const { entityType, entityId } = this.seoRule;

      try {
        const singleSeoRuleData = await seoRulesService.getSingleSeoRule(this.website.id, this.language.id, entityType, entityId)
        this.singleSeoRule = singleSeoRuleData?.data
      } catch (err) {
        // If entity is not found we will be creating new one, no additional checks needed
      }
    },

    async handleCreateSeoRule() {
      const createData = {
        ...this.singleSeoRule,
        websiteId: this.website.id,
        onlineLanguageId: this.language.id,
        target: {
          id: this.seoRule.entityId,
          type: seoRulesTargetTypeMapper[this.seoRule.entityType]
        }
      }

      try {
        await seoRulesService.create(createData);

        this.handleSuccess({ message: 'Successfully updated SEO rule' })
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    async handleUpdateSeoRule() {
      try {
        await seoRulesService.update(this.singleSeoRule.id, {
          content: this.singleSeoRule.content,
          metaDescription: this.singleSeoRule.metaDescription,
          metaTitle: this.singleSeoRule.metaTitle,
          pageTitle: this.singleSeoRule.pageTitle,
          noindex: this.singleSeoRule.noindex,
        });

        this.handleSuccess({ message: 'Successfully updated SEO rule' })
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    async submit() {
      !this.singleSeoRule.id ? await this.handleCreateSeoRule() : await this.handleUpdateSeoRule();
      this.handleSuccessCallback();
    },

    close() {
      this.resetError();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-checkbox {
  display: flex;
  align-items: center;
}

.u-mr-md {
  margin-right: $md !important;
}

.c-header__info {
  display: flex;
  flex-direction: column;

  .c-header__info-label {
    margin: 5px 0;
    color: $white;
    span {
      font-weight: 700;
    }
  }

  .c-header__info-label:nth-child(1) {
    margin-top: 15px;
  }
}
</style>
