<template>
  <div class="l-main">
    <md-tabs class="c-tabs c-tabs--small" :md-active-tab="currentLanguage.code">
      <md-tab
        v-for="language in languages"
        :id="language.code"
        :key="language.code"
        :md-label="language.code"
        @click="changeLanguage(language)"
      />
    </md-tabs>

    <div class="l-main__ctn">
      <div class="c-search">
        <div class="c-search-filter-row">
          <div class="u-flex">
            <md-field class="u-width-m u-mb-xs u-mr-md">
              <label>Search</label>
              <md-input v-model="searchQuery" @keydown.enter="searchSeo(true)" />
            </md-field>
            <md-field class="u-width-m u-mb-xs">
              <label>Search in:</label>
              <md-select id="customPage" v-model="selectedFilter" name="customPage" @md-selected="filterSelectedEvent()">
                <md-option
                  v-for="filter in availableFilters"
                  :key="filter.value"
                  :value="filter.value"
                >
                  {{ filter.label }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div>
            <generic-button variation="red" :disabled="searchQuery.length < 3" @click="searchSeo(true)">
              Search
            </generic-button>
            <md-tooltip v-show="searchQuery.length < 3">
              You must enter more than 2 characters to search SEO rules
            </md-tooltip>
          </div>
        </div>

        <div v-if="selectedFilter === 'region' || selectedFilter === 'league'" class="c-search-additional-fields">
          <p class="u-mb-xs u-mr-md">
            Additional fields:
          </p>
          <md-field v-if="selectedFilter === 'region' || selectedFilter === 'league'" class="u-width-m u-mb-xs">
            <label>Sport name</label>
            <md-input v-model="searchSportQuery" @keydown.enter="searchSeo(true)" />
          </md-field>

          <md-field v-if="selectedFilter === 'league'" class="u-width-m u-mb-xs">
            <label>Region name</label>
            <md-input v-model="searchRegionQuery" @keydown.enter="searchSeo(true)" />
          </md-field>
        </div>
      </div>

      <div v-if="fetchedResults && searchItems.length === 0" class="c-search-result-not-found">
        No results found
      </div>

      <div class="c-table-wrapper l-seo-rules__table">
        <base-table-component
          :prop-data="seoRulesTableData"
          sortable
          @sort="handleSort"
        >
          <template v-slot:default="slotProps">
            <generic-button
              icon
              transparent
              @click="editSeoRule(slotProps.selected.seoRule)"
            >
              <md-tooltip md-direction="top">
                Edit seo rule
              </md-tooltip>
              <md-icon>arrow_forward_ios</md-icon>
            </generic-button>
          </template>
        </base-table-component>
      </div>

      <paginate
        v-if="totalPages > 1"
        v-model="currentPage"
        :page-count="totalPages"
        :prev-text="'<'"
        :next-text="'>'"
        :page-range="20"
        :click-handler="searchSeo"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import GenericButton from '../../../../../shared/buttons/GenericButton';
import { jsonParse } from '@/helpers/transformers'
import { seoRulesService } from '@/services/seo-rules'
import get from 'lodash/get'
import { languageService } from '@/services/language'
import BaseTableComponent from '@/shared/table/BaseTableComponent'
import EditSeoRuleModal from './edit'
import { seoRulesTargetTypeMapper } from '@/const/seo-rules'

export default {
  name: "SeoRules",

  components: {
    GenericButton,
    BaseTableComponent
  },

  props: {
    website: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      searchQuery: "",
      searchSportQuery: "",
      searchRegionQuery: "",
      selectedFilter: "",
      searchItems: [],
      fetchedResults: false,
      languages: [],
      currentLanguage: {},
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      defaultLanguageId: null,
      availableFilters: [
        { label: 'All', value: '' },
        { label: 'Sports', value: 'sport' },
        { label: 'Regions', value: 'region' },
        { label: 'Leagues', value: 'league' },
        { label: 'Pages', value: 'page' },
      ],
      sortColumnActive: '',
      sortColumnDirection: '',
    };
  },

  computed: {
    seoRulesTableData() {
      return this.searchItems.map(seoRule => ({
        seoRule: seoRule,
        rowData: {
          name: seoRule.translation,
          ...this._getEntities(seoRule),
          id: this._getEntityId(seoRule),
        }
      }));
    },
  },

  created() {
    this.getAvailableLanguages();
  },

  methods: {
    async searchSeo(page = 1) {
      if (this.searchQuery.length < 3) {
        this.searchItems = []
        this.totalPages = 1;

        this.fetchedResults = false;
        return;
      }

      this.currentPage = typeof page !== 'number' ? 1 : page;

      const searchConstraints = {
        ...(this.searchQuery ? { term: this.searchQuery} : {}),
        ...(this.searchSportQuery ? { sport: this.searchSportQuery} : {}),
        ...(this.searchRegionQuery ? { region: this.searchRegionQuery} : {}),
        ...(this.selectedFilter ? { filter: this.selectedFilter} : {}),
        ...(this.currentPage ? { page: this.currentPage} : {}),
        ...(this.defaultLanguageId ? { defaultLanguageId: this.defaultLanguageId} : {}),
        ...(this.sortColumnActive ? { sortColumn: this.sortColumnActive } : {}),
        ...(this.sortColumnDirection ? { sortDirection: this.sortColumnDirection } : {})
      }

      const searchResponse = await seoRulesService.search(this.currentLanguage.id, searchConstraints)

      const {items, pageCount} = searchResponse.data;

      this.searchItems = items;
      this.totalPages = pageCount;

      this.fetchedResults = true;
    },

    changeLanguage(language) {
      this.currentLanguage = language;
      this.searchSeo();
    },

    async getAvailableLanguages() {
      const allLanguagesResponse = await languageService.getAllOnlineLanguages();
      const allOnlineLanguages = allLanguagesResponse.data.items;

      const additionalLanguages = jsonParse(get(this, 'website.betshop.market.additionalLanguages')) || [];
      const defaultLanguage = get(this, 'website.betshop.market.defaultLanguage', 'en');

      const betshopLanguageCodes = [...new Set([defaultLanguage, ...additionalLanguages])];

      this.languages = allOnlineLanguages.filter(onlineLanguage => betshopLanguageCodes.includes(onlineLanguage.code))
      this.currentLanguage = this.languages[0];
      this.defaultLanguageId = this.languages.find(language => language.code === 'en')?.id
    },

    editSeoRule(seoRule) {
      this.$modal.show(
        EditSeoRuleModal,
        {
          seoRule,
          website: this.website,
          language: this.currentLanguage
        },
        { height: 'auto', scrollable: true, width: '80%' }
      );
    },

    filterSelectedEvent() {
      this.searchRegionQuery = "";
      this.searchSportQuery = "";
      this.searchSeo();
    },

    _getEntities(seoRule) {
      const entities = {
        sport: '',
        region: '',
        type: '',
      }

      entities.type = seoRulesTargetTypeMapper[seoRule.entityType];

      switch (seoRule.entityType) {
        case 'SportEntity':
          entities.sport = seoRule.target.name;
          break;
        case 'RegionEntity':
          entities.region = seoRule.target.name;
          entities.sport = seoRule.target.sport.name;
          break;
        case 'LeagueEntity':
          entities.sport = seoRule.target.region.sport.name;
          entities.region = seoRule.target.region.name;
          break;
      }

      return entities;
    },

    _getEntityId(seoRule) {
      switch (seoRule.entityType) {
        case 'SportEntity':
          return seoRule.target.sportId;
        case 'RegionEntity':
          return seoRule.target.regionId;
        case 'LeagueEntity':
          return seoRule.target.leagueId;
        default:
          return '-'
      }
    },

    handleSort(column, direction) {
      this.sortColumnActive = column;
      this.sortColumnDirection = direction;
      this.searchSeo(this.currentPage);
    }
  },
};
</script>

<style lang="scss" scoped>
  .c-search {
    box-shadow: 4px 3px 6px -6px $grey-dark-01;
    padding: 15px 20px;
  }

  .c-search-filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .u-mr-md {
      margin-right: 16px !important;
    }
  }

  .c-search-additional-fields {
    display: flex;
    align-items: baseline;

    > div {
      margin-right: 10px;
    }
  }

  .c-search-result-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-top: 100px;
  }
</style>
