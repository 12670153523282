const initCustomButtonPlugin = (editor) => {
  const openDialog = () => editor.windowManager.open({
    title: 'Custom Button',
    body: {
      type: 'panel',
      items: [
        {
          type: 'input',
          name: 'label',
          label: 'Label'
        },
        {
          type: 'input',
          name: 'url',
          label: 'Url'
        },
        {
          type: 'selectbox',
          items: [
            {
              text: 'Primary',
              value: 'primary',
            },
            {
              text: 'Secondary Fill',
              value: 'secondary-fill',
            },
            {
              text: 'Secondary Outline',
              value: 'secondary-outline',
            },
          ],
          name: 'type',
          label: 'Type'
        }
      ]
    },
    buttons: [
      {
        type: 'cancel',
        text: 'Close'
      },
      {
        type: 'submit',
        text: 'Save',
        buttonType: 'primary'
      }
    ],
    onSubmit: (api) => {
      const data = api.getData();
      editor.insertContent(`<a href="${data.url}" class="button-link ${data.type}">${data.label}</button>`);
      api.close();
    }
  });

  editor.ui.registry.addButton('custombutton', {
    text: 'Add Button',
    icon: 'duplicate-row',
    onAction: () => {
      openDialog();
    }
  });

  editor.ui.registry.addMenuItem('custombutton', {
    text: 'Example plugin',
    icon: 'duplicate-row',
    onAction: () => {
      openDialog();
    }
  });

  return {
    getMetadata: () => ({
      name: 'Custom button plugin',
      url: ''
    })
  };
};

export default initCustomButtonPlugin;




