<template>
  <div class="l-main">
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-websites">
        <md-table v-model="tableData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" md-card>
          <md-table-empty-state
            md-label="No Casino Categories"
            :md-description="`There are no casino categories`"
          />

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Name" md-sort-by="rowData.name">
              {{ item.rowData.name }}
            </md-table-cell>
            <md-table-cell md-label="ID" md-sort-by="rowData.id">
              {{ item.rowData.id }}
            </md-table-cell>
            <md-table-cell md-label="Website Id" md-sort-by="rowData.websiteId">
              {{ item.rowData.websiteId }}
            </md-table-cell>
            <md-table-cell md-label="Active" md-sort-by="rowData.active">
              {{ item.rowData.active }}
            </md-table-cell>
            <md-table-cell md-label="Hidden" md-sort-by="rowData.hidden">
              {{ item.rowData.hidden }}
            </md-table-cell>
            <md-table-cell md-label="Archived" md-sort-by="rowData.archived">
              {{ item.rowData.archived }}
            </md-table-cell>
            <md-table-cell md-label="Games" md-sort-by="rowData.games">
              {{ item.rowData.games }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { casinoCategoriesService } from '@/services/casino-category'
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";

export default {
  name: "OnlineCasinoCategoriesList",
  mixins: [onlinePermissionsMixin],

  data() {
    return {
      onlyDevelopersAllowed: true,
      currentSort: 'rowData.name',
      currentSortOrder: 'desc',
      tableData: [],
      casinoCategories: [],
    }
  },

  computed: {
    ...mapGetters('account', ['user']),
  },

  created() {
    this.getAndSetTableData();
  },

  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async getAndSetTableData() {
      const response = await casinoCategoriesService.getAll();
      this.casinoCategories = response.data;
      this.tableData = casinoCategoriesService.prepareCasinoCategoriesDataFromResponse(this.casinoCategories);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
